import React from "react"
import SEO from "../components/seo"

import { CardLeft, CardRight, Line } from "../components/elements"
import Layout from "../components/layout"
import {
  HeaderPage,
  IntroSection,
  Services,
  ProductDetails,
  ReasonSection,
  ServiceDetail,
} from "../components/structure"

import headerIcon from "../images/content/headerIcon.svg"
import blog from "../images/content/blog.svg"
import caption from "../images/content/caption.svg"
import email from "../images/content/email.svg"
import engage from "../images/content/engage.svg"
import polished from "../images/content/polished.svg"
import webcopyright from "../images/content/webcopyright.svg"

export default function CustomisedContentWritingBlogging() {
  return (
    <Layout>
      <SEO title="Content writing, Blogging" />
      <HeaderPage
        src={headerIcon}
        color={props => props.theme.procolors.contentDark}
        bgColor={props => props.theme.procolors.contentLight}
        title="Increase traffic with stand-out content."
        catchy="Customised Content writing & Blogging "
        tagline="Our writers specialise in effective, original, and evergreen content that jumps off the page and boosts your online visibility."
      />
      <Line />
      <ServiceDetail>
        <IntroSection
          title="Content writing, Blogging"
          details="When it comes to growing your business and establishing your brand, high-quality content on your website and social media pages is a must. That’s why we offer customised content writing and blogging services."
          color={props => props.theme.procolors.contentDark}
        />
        <ProductDetails
          to="/contact/"
          title="The content writing and blogging services that we provide include:"
          color={props => props.theme.procolors.contentDark}
        >
          <CardLeft
            alt="Long and short-form blogs"
            title="Long and short-form blogs"
            detail=" Our talented team of writers create both long and short-form blogs to suit your specific website."
            src={blog}
          />
          <CardRight
            alt="Emails, brochures, and newsletters"
            title="Emails, brochures, and newsletters"
            detail="With our email, brochure, and newsletter service, you’ll be able to communicate effectively with other businesses and your customers."
            src={email}
          />
          <CardLeft
            alt="Social media captions"
            title="Social media captions"
            detail="Engage with your audience and build your following with our unique and attention-grabbing social media captions."
            src={caption}
          />
          <CardRight
            alt="Website copywriting"
            title="Website copywriting"
            detail="Fill your website with effective content that will convey your message and persuade your audience using our website copywriting service."
            src={webcopyright}
          />
          <CardLeft
            alt="Polished and edited content"
            title="Polished and edited content"
            detail="Our writers ensure that all content is polished and edited with no spelling or grammatical mistakes."
            src={polished}
          />
          <CardRight
            alt="Words that jump off the page and engage your audience"
            title="Words that jump off the page and engage your audience"
            detail="Using our content writing service, you’ll have access to lively content that jumps off the page and engages your audience."
            src={engage}
          />
        </ProductDetails>
        <ReasonSection
          borderColor={props => props.theme.procolors.contentLight}
          title="Why Do You Need Customised Content Writing and Blogging?"
          details="Words are powerful. All content that’s associated with your brand should be top quality and distinct. By having premium content, whether it’s in the form of landing page copy or email campaigns, you will drive more traffic to your website and engage more customers."
          details1="Blogging can also be an effective tool to grow your business. Through the use of keywords and other SEO strategies, blogging can attract more traffic to your website, establish you as an industry leader, and build customer loyalty."
        />
      </ServiceDetail>
      <Line />
      <Services />
    </Layout>
  )
}
